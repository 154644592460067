.poiDetailsView {
  position: relative;

  .ant-collapse-header {
    justify-content: center;
    color: $mainColor !important;

    &:hover {
      text-decoration: underline;
      color: darken($mainColor, $defaultHoverDarkenRatio) !important;
    }
  }

  .informations {
    .sectionTitle {
      margin: 25px 0 8px;
      display: block;
      font-weight: 600;
      font-size: 15px;
    }

    .sectionText {
      display: block;
      white-space: pre-wrap;
      margin-bottom: 10px;
    }
  }

  .formTopDescription {
    white-space: pre-wrap;
    font-weight: 600;
  }

  .userConnectButton {
    margin-top: 40px;
  }

  .cancelAssistanceButton {
    margin-top: 20px;

    font-size: 12px !important;
    padding: 10px 20px;
    min-width: auto;
  }

  #assistanceForm {
    margin-top: 20px;

    .ant-select-selector {
      box-shadow: none !important;
    }
  }

  .closedContainer {
    span {
      display: block;
    }

    .gif {
      width: 200px;
      margin-top: 30px;
    }
  }

  .favoriteSwitch {
    position: absolute;
    top: 5px;
    right: 0;
    z-index: 10;

    button {
      cursor: pointer;
      background: none;
      border: none;
      border-right: none;
      border-bottom-left-radius: 5px;
      z-index: 10;

      padding: 10px 14px;

      .anticon {
        color: $yellowColor;
        font-size: 30px;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.assistanceView .formContainer {
  margin-top: 50px;
}

#assistanceRequestContainer {
  position: relative;
  z-index: 100;

  .requestHeader {
    padding: 20px 12px;
    position: relative;
    background-color: #ffe9d0;
    overflow: hidden;

    &.requestHeaderSuccess {
      background-color: #b4f0e1;
    }

    &.requestHeaderError {
      background-color: #fd9191;
    }

    .anchor {
      position: absolute;
      top: -47px;
      margin-left: -30px;
      left: 50%;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      background-color: #f5f7f9;
      z-index: 1;
    }

    .requestHeaderContainer {
      margin-top: 15px;
      padding: 10px 8px 5px;
      background-color: #fff;
      border-radius: 10px;
      position: relative;

      .title {
        margin: 20px 20px 10px;
        font-size: 17px;
        font-weight: 600;
        color: $mainColor;
        display: block;
        text-align: center;
        text-transform: uppercase;
      }

      .date {
        font-size: 12px;
        font-weight: 600;
        display: block;
        text-align: center;
        opacity: 0.7;
        letter-spacing: 1.2px;
      }

      .subtitle {
        font-size: 13px;
        font-weight: 600;
        display: block;
        text-align: center;
      }

      .statusAnimationContainer {
        width: 140px;
        margin: 20px auto;

        .statusAnimation {
          width: 140px;
        }
      }

      .statusContainer {
        margin-top: 15px;
        text-align: center;

        .status {
          margin: 15px 5px 10px;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          display: block;

          &.statusSmallMargin {
            margin-top: 5px;
            margin-bottom: 10px;
          }

          .bold {
            font-weight: 600;
            color: $mainTextColor;
          }
        }

        .statusBottomAnimation {
          width: 70px;
          height: 70px;
          margin-top: -10px;
        }

        .timeline {
          display: flex;
          flex-direction: row;
          margin-top: 25px;
          position: relative;
          z-index: 1;

          .timelineSmall {
            flex: 1;
            height: 7px;
          }
          .timelineLarge {
            flex: 3;
            height: 7px;
          }
          .timelineMargin {
            margin-right: 5px;
          }

          .step {
            background-color: $disabledColor;
          }
          .stepDone {
            background-color: $successColor;
          }
        }

        .timelineAnimated {
          display: flex;
          flex-direction: row;
          margin-top: -7px;
          position: relative;
          z-index: 10;

          .timelineSmall {
            flex: 1;
            height: 7px;
          }
          .timelineLarge {
            flex: 3;
            height: 7px;
          }
          .timelineMargin {
            margin-right: 5px;
          }

          .timelineAnimatedStep {
            animation-iteration-count: infinite;
            animation-duration: 2s;
            animation-name: anim-request-status;
            opacity: 0;
            background-color: rgba(0, 0, 0, 0.3);
            height: 7px;
            visibility: hidden;

            &.active {
              visibility: visible;
            }
          }
        }
      }

      .topRightAction {
        position: absolute;
        top: 2px;
        right: 7px;

        border: none !important;
        background: none !important;
        padding: 5px 10px;

        color: $mainColor;
        font-size: 15px;
        line-height: 28px;
        font-weight: 600;
        outline: none !important;
        cursor: pointer;

        .icon {
          font-size: 22px;
          color: $mainColor;
          margin-top: 3px;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .retryContainer,
  .cancelContainer {
    margin: 40px 0 30px;
    display: block;
  }

  .cancelContainer {
    opacity: 0.75;

    &:hover {
      opacity: 1;
    }
  }

  .closingContainer {
    margin: 30px auto 20px;

    .topLabel {
      display: block;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .rating {
      display: block;
      margin-bottom: 20px;

      .ant-rate {
        font-size: 36px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #assistanceRequestContainer {
    padding: 20px !important;

    .statusAnimationContainer {
      width: 60px !important;
      .statusAnimation {
        width: 60px !important;
      }
    }
  }
}

@keyframes anim-request-status {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 0.85;
  }
}
