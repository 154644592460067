.accountProfile {
  .formContainer {
    margin-top: 60px;
    max-width: 600px;
  }

  .profileInfo {
    div.item {
      display: block;
      font-size: 18px;
      margin: 5px 0;

      .anticon {
        margin-right: 10px;
        color: $mainColor;
        font-size: 15px;
      }
    }
  }

  .editAction {
    display:inline;
    margin-left:8px;
    font-size:20px;
    color:$mainColor;
    cursor:pointer;
    
    &:hover {
      color: darken($mainColor, $defaultHoverDarkenRatio);
    }    
  }

  .logoutButton {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    background: none;
    border: none;

    .anticon {
      color: $redColor;
      font-size: 20px;
    }
  }

  .vehiclesList,
  .favoritesList {
    .addButton {
      margin-top: 40px;
    }
  }

  .favoritesList {
    .item {
      width: 100%;
      position: relative;
      transition: all 0.4s;

      .deleteFavoriteButton {
        position: absolute;
        top: 5px;
        right: 0;
        cursor: pointer;
        background: none;
        border: none;
        z-index: 10;
        display: none;

        .anticon {
          color: $redColor;
          font-size: 16px;
        }
      }

      .poiListItem {
        text-align: left;
      }

      &:hover .deleteFavoriteButton {
        display: block;
      }
    }
  }
}

.vehicleFormModal {
  .separator {
    width: 100%;
    height: 1px;
    margin: 40px 0;
    background-color: $greyColor;
    opacity: 0.2;
  }

  .ant-picker {
    display: block;
  }

  .formError {
    color: $redColor;
    font-size: 14px;
    font-weight: 600;
    display: block;
    margin: 25px 0 0;
    text-align: center;
  }
}

.userVehicleItem {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(112, 112, 112, 0.25);
  background-color: #ffffff;
  padding: 17px 23px;
  border: 1px solid rgba(100, 100, 100, 0.05);
  margin-bottom: 12px;
  color: $mainTextColor;
  cursor: pointer;
  transition: all 0.4s;
  position: relative;

  width: 100%;

  &.selected:not(.notSelectable) {
    border-color: $mainColor;
  }

  &:hover:not(.notSelectable) {
    box-shadow: 0px 2px 6px -2px rgba(90, 90, 90, 0.35);
    border-color: $mainColor;
  }

  &.notSelectable {
    cursor: auto !important;
  }

  .left {
    flex-grow: 0;
    width: 80px;
    margin-right: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;

    .anticon {
      font-size: 40px;
    }
  }

  .middle {
    flex-grow: 1;

    span {
      display: block;
      font-weight: 500;
      letter-spacing: 0.8px;
      font-size: 13px;
      line-height: 15px;
      text-align: left;

      &.name {
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        font-size: 15px;
        line-height: 1;
        margin-bottom: 6px;
      }
    }

    .category {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .deleteVehicle {
    position: absolute;
    top: 5px;
    right: 0;
    cursor: pointer;
    background: none;
    border: none;
    z-index: 10;
    display: none;

    .anticon {
      color: $redColor;
      font-size: 16px;
    }
  }

  &:hover .deleteVehicle {
    display: block;

    &:hover {
      opacity:0.6
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 10px 12px;

    .left {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
      position: relative;
    }

    .middle {
      text-align: center;

      span.name {
        text-align:center;
      }

      .category {
        justify-content: center;
      }
    }
  }
}

@media screen and (max-width: 768px) { 
  .favoritesList {
    .item {
      .deleteFavoriteButton {
        display:block !important
      }
    }
  }
}