/* Constants */
$mobileResponsiveWidth: 768px;
$desktopResponsiveWidth: 1200px;
$defaultOpacityForShadow: 0.3;
$defaultHoverDarkenRatio: 10%;

$headerHeight: 80px;
$footerHeight: 70px;

/* Colors */
$mainTextColor: #00516e;
$mainBackgroundColor: #f6f6f6;

/* Interaction colors */
$whiteHoverBackground: rgba(220,220,220, 0.1);

// Color palette
$mainColor: #56baed;
$thirdColor: #e9f3fb;
$greyColor: #82959e;
$successColor: #4CDCB7;
$redColor: #ED5656;
$orangeColor: #f5a120;
$disabledColor: #D4DCE1;
$yellowColor: #ede856;