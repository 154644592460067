body {
  margin: 0;
  padding: 0;
  font-family: "Lato", "Arial";
  font-weight: $regularFontWeight;
  color: $mainTextColor;
  background-color: $mainBackgroundColor;
}

#mainBody {
  min-height: calc(100vh - $headerHeight - $footerHeight);
  background-color: #fff;
}

.layout.withBackground {
  background-image: url("../images/site_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #96cee9;
  background-attachment: fixed;

  .main_header,
  #mainBody,
  .ant-layout-header .ant-menu {
    background-color: transparent;
  }
}

/* Header */

.main_header {
  background-color: white;

  .logo {
    height: 60px;
  }
}

/* Footer */
footer {
  background-color: $mainBackgroundColor !important;

  .links {
    display: flex;
    flex-direction: row;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      flex-grow: 1;

      li {
        display: inline-block;
        padding: 0 25px;

        border-right: 1px solid $greyColor;

        &:last-child {
          border-right: none;
        }
      }
    }

    .copyright {
      flex-grow: 0;
      align-self:center;
    }
  }
}

/* Customize AntDesign */
.ant-layout-header .ant-menu {
  border-bottom: none;
}

.ant-menu-item {
  &.warning {
    color: $orangeColor !important;

    .anticon {
      font-size: 18px;
    }
  }

  &.flash {
    animation-iteration-count: infinite;
    animation-duration: 1s;
    animation-name: text-flash;
  }
}

@keyframes text-flash {
  from {
    opacity: 0.6;
  }
  to {
    opacity: 1;
  }
}

/* Main layout */
#homepage {
  .topBlocRow {
    background-image: url("../images/site_background.png");
    background-size: cover;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
  }

  .topBloc {
    margin: 0px 0 15px;
    display: flex;
    flex-direction: row;
    align-content: center;

    .left {
      flex-grow: 1;
      align-self: center;

      h1 {
        font-weight: 900 !important;
        color: $mainColor;
        font-size: 24px !important;
        line-height: 32px !important;
        margin: 0 0 30px;
        text-transform: uppercase;
        display: block;
      }

      span.desc {
        font-size: 15px;
        line-height: 28px;
        white-space: pre-wrap;
        text-align: justify;
        display: block;
      }
    }

    .right {
      .videoContainer {
        width: 500px;
        height: 661px;
        background: transparent url(../images/smartphone_placeholder.png) no-repeat;
        background-size: 100% 100%;

        .player {
          margin: 0 auto;

          video {
            margin-top: 100px;
            margin-left: -3px;
            overflow: hidden;
            border-radius: 20px;
          }
        }
      }
    }
  }

  .searchContainer {
    margin-top: -40px;
    margin-bottom: 60px;
  }

  .homepageBloc {
    margin-bottom: 80px;

    span {
      display: block;

      &.title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      &.desc {
        text-align: justify;
        font-size: 16px;
        white-space: pre-wrap;
      }
    }

    .imageContainer {
      text-align: center;

      img {
        max-width: 90%;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .ant-layout-header {
    padding-left: 20px;
    padding-right: 20px;
  }

  #homepage {
    .topBloc {
      flex-direction: column;

      margin-top: 60px;

      .left {
        padding: 0 35px;
      }

      .right {
        flex-grow: 1;
        text-align: center;
        overflow: hidden;

        .videoContainer {
          margin: 0 auto;
        }
      }
    }
  }

  .homepageBloc {
    padding: 0 20px;

    .imageContainer {
      margin-top: 20px;

      img {
        max-width: 90% !important;
      }
    }

    &.bloc3 .ant-row {
      flex-direction: column-reverse;
    }
  }

  footer {
    .links {
      ul {
        li {
          display: block;
          border: none;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .ant-layout-header {
    padding-left: 10px;
    padding-right: 10px;
  }

  #homepage {
    .topBlocRow {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .topBloc {
      .right {
        position: relative;
        height: 660px;

        .videoContainer {
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -245px;
        }
      }
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.partnersBloc {
  margin: 40px 0 60px;
  text-align: center;

  .title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 60px;
    display: block;
    text-align: center;
  }

  .partner {
    text-align: center;
    margin: 0 auto 30px;

    img {
      max-height: 60px;
      width: auto;
      margin: 0 auto;
    }
  }

  .slick-arrow {
    height: 50px;
    opacity: 1;
    margin-top: -35px;

    &:hover {
      opacity: 0.85;
    }

    &.slick-prev {
      margin-right: 15px;
    }

    &.slick-next {
      margin-left: 15px;
    }
  }

  .ant-carousel {
    margin: 0 25px;
  }
}
