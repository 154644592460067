.planView {
  max-height: 100%;
  height: calc(100vh - $headerHeight);
  display: flex;
  flex-flow: row wrap;
  background: white;

  .listContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
  }
}

#planListContainer {
  overflow-y: auto;
  padding: 0 20px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.poiListItem {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  box-shadow: 0px 2px 6px -2px rgba(112, 112, 112, 0.25);
  background-color: #ffffff;
  padding: 17px 23px;
  border: 1px solid rgba(100, 100, 100, 0.05);
  margin-bottom: 12px;
  color: $mainTextColor;
  cursor: pointer;
  transition: all 0.4s;
  position: relative;
  z-index: 0;

  &.selected:not(.notSelectable) {
    border-color: $mainColor;
  }

  &:hover:not(.notSelectable) {
    box-shadow: 0px 2px 6px -2px rgba(90, 90, 90, 0.35);
    border-color: $mainColor;
  }

  &.notSelectable {
    cursor: auto !important;
  }

  .left {
    flex-grow: 0;
    width: 180px;
    margin-right: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 10;
      position: relative;
      border-radius: 7px;
    }
  }

  .middle {
    flex-grow: 1;

    span {
      display: block;
      font-weight: 500;
      letter-spacing: 0.8px;
      font-size: 13px;
      line-height: 15px;

      &.name {
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        font-size: 15px;
        line-height: 1;
        margin-bottom: 6px;
      }
    }

    .addressContainer {
      margin-top: 8px;
      display: block;
      margin-bottom: 4px;

      span {
        color: $greyColor;
      }
    }

    .category {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 18px;
        margin-left: 5px;
      }
    }
  }

  .right {
    flex-grow: 0;
    display: flex;
    flex-direction: column;

    .distanceContainer {
      align-self: flex-end;
      margin-top: 10px;
      justify-items: flex-end;
      flex-grow: 1;
      display: flex;
    }
  }

  .searchMapLink {
    z-index: 100;
    overflow: hidden;
    transition: all 0.25s;
    padding: 2px 5px 3px;
    margin-left: 3px;
    display: inline !important;

    cursor: pointer;

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    img {
      width: 15px;
      transition: all 0.4s;
    }
  }

  @media (max-width: 991px) {
    .searchMapLink {
      display: none;
    }
  }

  .distanceContainer {
    .distance {
      height: 36px;
      width: 100px;
      border-radius: 20px;
      box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.09);
      padding: 3px;
      text-align: center;
      background-color: #fff;
      align-self: flex-end;

      span {
        font-weight: 800;
        font-size: 13px;
        line-height: 30px;
      }
    }
  }

  .favoriteIcon {
    color: $yellowColor;
    display: inline !important;
    margin-right: 5px;
    font-size: 20px !important;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 10px 12px;

    .left {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
      position: relative;

      .distanceContainer {
        position: absolute;
        z-index: 10;
        bottom: -15px;
        right: 5px;
        display: block;

        .distance {
          width: auto;
          padding: 3px 20px;
        }
      }

      &.withDistance {
        margin-bottom: 20px;
      }
    }

    .middle {
      text-align: center !important;

      .category {
        justify-content: center;
      }
    }

    .right {
      .distanceContainer {
        align-self: center;
      }
    }
  }
}

.mapContainer {
  height: calc(100vh - $headerHeight);

  #map {
    height: 100%;
    width: 100%;
  }
}

.poisMapInfoWindow {
  display: flex;
  flex-direction: row;
  padding: 20px 10px 5px;
  color: $mainTextColor;
  cursor: pointer;
  transition: all 0.4s;
  position: relative;

  .left {
    flex-grow: 0;
    width: 80px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

    img {
      width: 100%;
      object-fit: cover;
      z-index: 10;
      position: relative;
    }
  }

  .middle {
    flex-grow: 1;

    span {
      display: block;
      font-weight: 400;
      letter-spacing: 0.8px;
      font-size: 13px;
      line-height: 15px;

      &.name {
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        font-size: 15px;
        line-height: 1;
        margin-bottom: 6px;
      }
    }

    .addressContainer {
      margin-top: 8px;
      display: block;
      margin-bottom: 4px;

      span {
        color: $greyColor;
        line-height: 1.2;
      }
    }

    .category {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 18px;
        margin-left: 5px;
      }
    }
  }
}

.gm-style-iw .gm-style-iw-chr {
  display: none;
}

.userLocationWrapper {
  padding: 10px 5px;
  flex-grow: 1;

  .userLocationLabels {
    span.desc {
      display: block;
      font-size: 14px;
      text-align:left;
    }

    button.selector {
      color: $mainTextColor;
      display: inline-block;
      font-weight: 600;
      font-size: 16px;
      border: none;
      outline: none;
      background: none;
      padding: 0;
      cursor: pointer;
      text-align:left;

      &:hover {
        color: darken($mainTextColor, $defaultHoverDarkenRatio);

        .anticon {
          color: darken($mainColor, $defaultHoverDarkenRatio);
        }
      }

      .anticon {
        color: $mainColor;
        font-size: 14px;
      }
    }
  }
}

.itinerarySimulation {
  flex-grow: 0;
  padding: 10px 5px;
}

.addressFormModal {
  .desc {
    display: block;
    margin-bottom: 15px;
  }

  .inputsContainer {
    display: flex;
    flex-direction: row;

    div:first-child {
      flex-grow: 1;
    }

    .userPosition {
      flex-grow: 0;
      width: 40px;
      margin-left: 10px;
      border: 1px solid hsl(0, 0%, 80%);
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      background: none;
      padding: 0;

      &:hover {
        opacity: 0.75;
      }

      img {
        width: 20px;
        margin-left: -2px;
      }
    }
  }

  .itinerarySeparator {
    display: block;
    margin: 10px 0;
    text-align: center;
    font-size: 24px;
  }

  .distanceThrehsoldContainer {
    margin-top: 25px;
    padding-top: 15px;
    border-top: 1px solid hsl(0, 0%, 92%);

    span.title {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 5px;
      display: block;
    }

    span.desc {
      font-size: 13px;
      display: block;
    }

    span.threhsold_label {
      display: block;
      text-align: center;
      font-size: 12px;
      b {
        color: $mainColor;
        font-weight: 600;
      }
    }
  }
}

.searchContainer {
  flex-shrink: 0;
  margin-bottom: 20px;

  .search_bar {
    width: 100%;
    position: relative;

    .ant-input {
      width: 100%;
      height: 75px;
      padding: 28px 80px 24px 40px;
      border-radius: 18px !important;
      box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      border-color: transparent;

      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;

      color: $mainTextColor;

      &:hover,
      &:focus {
        border-color: $mainColor !important;
      }

      &::placeholder,
      &:-moz-placeholder,
      &::-webkit-input-placeholder,
      &:-ms-input-placeholder,
      &::-ms-input-placeholder {
        color: $mainColor !important;
      }
    }

    .ant-input-group-addon {
      position: absolute !important;
      right: 15px !important;
      top: 20px !important;
      left: auto !important;
      width: auto !important;
      background: transparent !important;
      z-index: 100 !important;

      button {
        border: none !important;
        border-radius: 0 !important;
        background: transparent !important;
        width: auto !important;
        box-shadow: none !important;
      }
    }

    .ic_search {
      width: 23px;
      height: 23px;
      object-fit: contain;
    }
  }

  .search_bar_small {
    margin-top: 5px;

    .ant-input {
      height: 55px;
      padding: 14px 80px 14px 40px;
      border-radius: 12px !important;

      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    }

    .ant-input-group-addon {
      top: 10px !important;
    }

    &.search_with_filters {
      width: calc(100% - 75px);
      float: left;
      margin-left: 5px;
    }
  }
}

.search_filter_toggle,
.simulate_itinerary_button {
  margin-top: 5px;
  height: 55px;
  width: 55px;
  border-radius: 12px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: 1px solid transparent;

  float: right;

  transition: all 0.3s;

  &.simulate_itinerary_button {
    width: auto;
    height: auto;
    padding: 0 15px;
    border-radius: 6px;
    background-color: $orangeColor;
    color: #fff;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($orangeColor, $defaultHoverDarkenRatio);
      border-color: $orangeColor !important;
    }

    .action {
      line-height: 38px;

      .anticon {
        margin-left: 6px;
      }
    }
  }

  @media screen and (max-width: 650px) {
    &.simulate_itinerary_button {
      span.text {
        display: none !important;
      }
      .anticon {
        margin-left: 0 !important
      }
    }
  }

  div.action {
    cursor: pointer;
    width: 100%;
    height: 100%;
    float: left;

    img {
      width: 25px;
      margin-top: 14px;
      margin-left: 14px;
    }
  }

  &:hover,
  &:focus,
  &.active {
    border-color: $mainColor !important;
  }
}

@media screen and (max-width: 768px) {
  .searchContainer {
    .search_bar_small .ant-input {
      padding-left: 20px;
      padding-right: 60px;
    }
  }
}

.map_filters {
  background-color: #ffffff;
  border: 1px solid $mainColor;
  border-radius: 12px;
  padding: 13px 22px;
  margin-bottom: 15px;
  position: relative;
  letter-spacing: 0;

  overflow: hidden;

  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.1);

  .top_title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
    display: block;
  }

  .activeCheckbox {
    margin-bottom: 15px;

    font-weight: 600;
    font-size: 15px;

    span {
      color: $mainColor;
    }
  }

  .checkbox {
  }

  .filters_cols {
    display: flex;
    flex-direction: row;

    .filters_col {
      flex: 1;

      .col_title {
        font-weight: 600;
        font-size: 15px;
        color: $mainColor;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
          span {
            color: $greyColor;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

.filters_count_container {
  padding-left: 20px;
  letter-spacing: 0;
  margin-bottom: 12px;

  span.results_count {
    color: $mainColor;
    font-size: 15px;
    line-height: 24px;
    font-weight: 600;
    display: block;
    margin-bottom: 8px;
  }

  span.filters_count {
    font-weight: 600;
    font-size: 13px;
    display: block;

    a {
      font-size: 12px;
      margin-left: 15px;
      text-decoration: underline;
      font-weight: 400;
    }
  }

  .reset_filters {
    color: $mainColor;
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    margin-left: 10px;

    &:hover {
      text-decoration: underline;
    }
  }
}
