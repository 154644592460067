.notConnectedContainer,
.accountProfile,
.floatingMainContainer {
  .formContainer {
    position: relative;
    z-index: 10;

    margin: 130px auto 30px;
    width: 100%;
    max-width: 420px;

    border-radius: 10px 10px 10px 10px;
    background: #fff;

    box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
    text-align: center;

    user-select: none;

    .ant-form {
      padding: 0 !important;
    }

    &.registerForm {
      margin-top: 80px;
    }

    .formContent {
      padding: 35px 40px;
      position: relative;

      .formTitle {
        font-weight: 600;
        letter-spacing: 0.8px;
        font-size: 28px;
        display: block;
        margin-bottom: 35px;
      }

      .submitButton {
        margin-top: 20px 20px 5px 20px;
      }

      .ant-input-affix-wrapper {
        border: none;
        background-color: #f6f6f6;
        color: #0d0d0d;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        -webkit-border-radius: 5px 5px 5px 5px;
        border-radius: 5px 5px 5px 5px;
        padding: 8px 15px;

        &:hover {
          background-color: #f6f6f6;
        }

        input {
          background-color: transparent;
          border: none;
          padding: 10px 15px;

          &:hover {
            background-color: transparent !important;
          }
        }

        input:placeholder {
          color: #cccccc;
        }
      }

      .ant-picker,
      .ant-select {
        border: none;
        background-color: #f6f6f6;
        color: #0d0d0d;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        -webkit-border-radius: 5px 5px 5px 5px;
        border-radius: 5px 5px 5px 5px;
        padding: 16px 15px;
        display: block;

        .ant-picker-input {
          flex-direction: row-reverse;

          .ant-picker-suffix {
            margin-left: 0;
            margin-right: 19px;
            color: #0d0d0d;
          }
        }

        .ant-select-selector {
          border: none;
          background-color: transparent;
          font-size: 14px;
          text-align: left;
        }
      }

      .ant-form-item-has-error {
        .ant-input-affix-wrapper {
          box-shadow: 0 0 0 2px rgba($redColor, $defaultOpacityForShadow);
        }

        .ant-input-prefix {
          color: $redColor;
        }
      }

      .ant-picker-status-error {
        box-shadow: 0 0 0 2px rgba($redColor, $defaultOpacityForShadow);

        .ant-picker-suffix {
          color: $redColor !important;
        }
      }

      .ant-select-status-error {
        box-shadow: 0 0 0 2px rgba($redColor, $defaultOpacityForShadow);

        .ant-select-suffix {
          color: $redColor !important;
        }
      }

      .forgotPassword {
        margin-top: 25px;
        display: inline-block;
      }

      .formError {
        color: $redColor;
        font-size: 14px;
        font-weight: 600;
        display: block;
        margin: 25px 0 0;
        text-align: center;
      }
    }

    .formFooter {
      background-color: #f6f6f6;
      border-top: 1px solid #dce8f1;
      padding: 25px;
      text-align: center;
      border-radius: 0 0 10px 10px;
    }

    .separator {
      width: 100%;
      height: 1px;
      margin: 40px 0;
      background-color: $greyColor;
      opacity: 0.2;
    }
  }
}

// Responsive
@media (max-width: $mobileResponsiveWidth) {
  .notConnectedContainer,
  .accountProfile,
  .floatingMainContainer {
    .formContainer {
      max-width: none !important;
      margin: 20px 10px !important;
      width: calc(100% - 20px) !important;
    }
  }
}

.floatingMainContainer {
  .formContainer {
    max-width: 600px;

    .poiListItem {
      box-shadow: none !important;
      border: none !important;
      margin-bottom: 0;

      .middle {
        text-align: left;
      }
    }

    .separator {
      margin: 20px 0;
    }
  }
}

.forgotPasswordSuccess {
  img {
    width: 200px;
    display: block;
    margin: 0 auto 20px;
  }

  span {
    font-size: 16px;
  }
}
